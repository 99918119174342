<template>
  <div
    ref="lavContainer"
    :style="{
      overflow: 'hidden',
    }"
    @click="playAnimation"

  />
</template>

<script>
import lottie from 'lottie-web'

export default {
  props: {
    // This will be the icon json file
    json: {
      type: Object,
      required: true,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    pauseAt: {
      type: Number
    }
  },

  mounted() {
    // When the component is mounted, lottie will do it's work creating a svg and renders the icon.
    this.anim = lottie.loadAnimation({
      container: this.$refs.lavContainer,
      renderer: 'svg',
      animationData: this.json,
      autoplay: true, // Prevents playing on load
      loop: true, // Option whether you want to keep playing
    })
    if(this.pauseAt != 0){
      setTimeout(() => this.anim.pause(), this.pauseAt)
    }
  },

  methods: {
    playAnimation() {
      // this.anim.pause() // Starts animation
      this.anim.goToAndPlay(1) // Starts animation
      if(this.pauseAt != 0){
        setTimeout(() => this.anim.pause(), this.pauseAt)
      }
    },
    stopAnimation() {
      this.anim.stop() // Stops animation
    },
  },
}
</script>