<template>
  <icon-lottie :json="json" :pauseAt="0" />
</template>

<script>
  import IconLottie from './Lottie'
  import json from './coin.json'

  export default {
    components: {
      IconLottie,
    },

    data() {
      return {
        json,
      }
    },
  }
</script>